var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"34jEtLKR6BlmmvPmlFxpZ"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable no-param-reassign */
// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import removeSensitiveData from '@utilities/helpers/remove-sensitive-data';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  environment: process.env.ENVIRONMENT || 'local',
  dsn:
    SENTRY_DSN ||
    'https://95f25fb53ac34fcfb6a36e5adf7b2648@o474424.ingest.sentry.io/5749227',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  beforeSend(event) {
    if (event.user) {
      event.user = removeSensitiveData(event.user);
    }

    if (event.breadcrumbs) {
      event.breadcrumbs = event.breadcrumbs.map((breadcrumb) => ({
        ...breadcrumb,
        data: removeSensitiveData(breadcrumb.data),
      }));
    }

    return event;
  },
  // beforeBreadcrumb(breadcrumb) {
  //   // eslint-disable-next-line no-param-reassign
  //   breadcrumb.data = removeSensitiveData(breadcrumb.data);

  //   return breadcrumb;
  // },
});
